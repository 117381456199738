<template>
	<div class="dynamicpanels fill-height">
		<panel-wrapper :options="activeDynamicPanel" :type="getType(activeDynamicPanel)" />
	</div>
</template>

<script>
import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import PanelWrapper from './PanelWrapper';

const PANEL_MAP = {
	[NOTIFICATIONS_LAYER]: 'notification-panel'
};

export default {
	name: 'dynamicpanels',

	components: {
		PanelWrapper
	},

	data() {
		return {
			changeKey: 0
		};
	},

	computed: {
		panels() {
			console.warn(this.$store.getters.dynamicPanels);
			return this.$store.getters.dynamicPanels;
		},

		activePanel() {
			return this.$store.getters.activePanel;
		},
		activePanelOptions() {
			return this.$store.getters.activePanelOptions;
		},

		activeDynamicPanel() {
			return this.panels.find(({ panelId }) => panelId === this.activePanel);
		}
	},
	watch: {
		activePanel(val) {
			console.log('active panel', val);
		},
		activePanelOptions(val) {
			console.log('active panel options', val);
			if (val.data) this.$store.commit('setDeviceZoom', val.data);
		}
	},
	methods: {
		getType({ identifier }) {
			return PANEL_MAP[identifier];
		}
	}
};
</script>
